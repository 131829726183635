// src/pages/_app.tsx
import '../styles/globals.css';
export { reportWebVitals } from 'next-axiom';

import { AnalyticsProvider, useAnalytics } from 'contexts/analytics';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import NextNProgress from 'nextjs-progressbar';
import { useEffect } from 'react';

import { trpc } from '../utils/trpc';

const PROGRESSBAR_OPTIONS = {
  color: '#EE6D8A',
  startPosition: 0.3,
  stopDelayMs: 200,
  height: 3,
  showOnShallow: true,
};

const MyApp = ({
  Component,
  pageProps,
}: AppProps<{
  session: Session;
}>) => {
  return (
    <>
      <NextNProgress {...PROGRESSBAR_OPTIONS} />
      <SessionProvider session={pageProps.session}>
        <AnalyticsProvider>
          <PageWrapper>
            <Component {...pageProps} />
          </PageWrapper>
        </AnalyticsProvider>
      </SessionProvider>
    </>
  );
};

function PageWrapper({ children }: { children: JSX.Element }) {
  const router = useRouter();
  const analytics = useAnalytics();

  useEffect(() => {
    router.events.on('routeChangeComplete', (url) => analytics.page(url));
  }, [router.events, analytics]);

  return children;
}

export default trpc.withTRPC(MyApp);
