import { AnalyticsBrowser } from '@segment/analytics-next';
import React, { ReactNode } from 'react';

const AnalyticsContext = React.createContext<
  | {
      analytics: AnalyticsBrowser;
      writeKey: string;
    }
  | undefined
>(undefined);

export const AnalyticsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const writeKey = process.env.NEXT_PUBLIC_SEGMENT_WRITEKEY || '';
  const analytics = React.useMemo(() => {
    return AnalyticsBrowser.load({
      writeKey,
    });
  }, [writeKey]);

  return (
    <AnalyticsContext.Provider value={{ analytics, writeKey }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

// Create an analytics hook that we can use with other components.
export const useAnalytics = () => {
  const result = React.useContext(AnalyticsContext);
  if (!result) {
    throw new Error('Context used outside of its Provider!');
  }
  return result.analytics;
};
